<template>
  <div class="reviewView" v-if="monitor">
    <router-link class="reviewView__close" to="revisionhabitaciones">
      <p>
        Salir
        <i class="fas fa-door-open "></i>
      </p>
    </router-link>
    <Avatar class="reviewView__img" :user="monitor" />
    <div class="reviewView__title">Models 1A</div>
    <section class="reviewView__card">
      <h4 class="reviewView__subTitle">Revisión de habitaciones</h4>
      <div v-if="models.length">
        <div class="reviewView__msg" v-html="msg"></div>
      </div>
      <div v-else>
        <div class="reviewView__msg" v-html="msgNoExist"></div>
      </div>
    </section>
    <section class="reviewView__item" v-if="models.length">
      <modelReview v-for="model in models" :key="model._id" :model="model" :monitor="monitor" />
    </section>
    <div v-if="models.length < totalModels">
      <button class="reviewView__more click-on-bottom" @click="showMore((cpage += 1))">ver más</button>
    </div>
    <div class="spinner" v-if="isLoading"></div>
  </div>
</template>

<script>
import modelReview from "@/views/review/modelReview";
import reviewRoom from "@/components/widgets/Reviews";
import axios from "@/api/axios";
import { socket } from "@/socket";

export default {
  components: {
    reviewRoom,
    modelReview,
  },
  data() {
    return {
      showForm: false,
      data: null,
      limit: 15,
      cpage: 1,
      page: 1,
      isLoading: false,
      models: [],
      monitor: null,
      totalModels: null,
      msg: null,
      msgNoExist: null,
    };
  },
  async beforeMount() {
    const dataIntern = localStorage.getItem("a1liame");
    const dataToken = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
    if (dataIntern && dataToken) {
      this.data = dataIntern;
    } else {
      this.$router.go(-1);
      return;
    }
    socket?.on("postReviewRefresh", async (model) => {
      // const userIndex = this.models.findIndex((i) => i._id === model._id);
      // if (userIndex > -1) {
      //   this.models.splice(userIndex, 1, model);
      // }
      this.page = 1;
      await this.reloadData();
    });
    await this.$store.dispatch("cities/fetch");
    await this.$store.dispatch("offices/fetch");
    await this.$store.dispatch("rooms/fetch");
    await this.getDataModels();
    this.msg = `Hola <strong>${this.monitor.user}</strong>,
    esta es la lista de modelos para revisar las habitaciones en la ciudad de
    <strong>${this.monitor.city}</strong> en la sede
    <strong>${this.monitor.office}</strong>.`;
    this.msgNoExist = `Hola <strong>${this.monitor.user}</strong>,
    no hay modelos para revisión de habitación en
    <strong>${this.monitor.city}</strong> en la sede
    <strong>${this.monitor.office}</strong>. en este momento.`;
  },
  mounted: async function() {
    this.$root.$on("postReviewRefresh", async (data) => {
      // const userIndex = this.models.findIndex((i) => i._id === data._id);
      // if (userIndex > -1) {
      //   this.models.splice(userIndex, 1, data);
      // }
      this.page = 1;
      await this.reloadData();
    });
  },
  beforeDestroy() {
    localStorage.removeItem("a1liame");
    localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
  },
  methods: {
    async reloadData() {
      let query = `?user=${this.data}&limit=${this.limit}&page=1`;
      const allData = await axios.get(`/reviews/reviewsBoard${query}`);
      if (allData) {
        this.models = [];
        this.models = allData.data.modelsByMonitor;
        this.monitor = allData.data.monitorData;
        this.totalModels = allData.data.totalModels;
      }
    },
    async showMore(cpage) {
      this.isLoading = true;
      this.page = cpage;
      await this.getDataModels();
      this.isLoading = false;
    },
    async getDataModels() {
      let query = `?user=${this.data}&limit=${this.limit}&page=${this.page}`;
      const allData = await axios.get(`/reviews/reviewsBoard${query}`);
      if (allData) {
        this.models.push(...allData.data.modelsByMonitor);
        this.monitor = allData.data.monitorData;
        this.totalModels = allData.data.totalModels;
      }
    },
  },
};
</script>

<style lang="scss">
.reviewView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $mpadding * 2 $mpadding;
  margin: auto;
  min-height: 100vh;
  font-size: 16px;
  text-align: center;
  font-family: Montserrat;
  background-color: $white_smoke;
  &__img {
    width: 3.5rem;
    height: 3.5rem;
    flex-shrink: 0;
  }
  &__card {
    width: auto;
    max-width: $tablet_width;
    padding: $mpadding;
    margin: 30px 0;
    border-radius: $mradius;
    box-shadow: $dshadow;
    background: $white;
  }
  &__title {
    font-size: 30px;
    font-family: RoundedElegance;
    font-weight: bold;
    color: $lipstick;
  }
  &__subTitle {
    margin-bottom: 20px;
  }
  &__msg {
    font-size: 16px;
    font-family: $first_font;
  }
  &__item {
    width: 100%;
    max-width: 768px;
    justify-content: center;
    align-items: center;
  }
  &__more {
    border: none;
    background-color: transparent;
    color: $lipstick;
    opacity: 0;
  }
  &__close {
    display: block;
    color: $lipstick;
    font-size: 16px;
    text-decoration: none;
    outline: none;
    margin-left: 80%;
    &:hover {
      outline: none;
      text-decoration: none;
    }
  }
}
</style>
