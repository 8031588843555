<template>
  <div class="modelReview">
    <li class="modelReview__li" @click="showFormReview(), objectPropForm()">
      <div class="modelReview__info">
        <Avatar class="modelReview__img" :user="model" />
        <div class="modelReview__items">
          <p class="modelReview__item">{{ model.artisticName }}</p>
          <p class="modelReview__item " v-html="`Habitación:<strong>${model.room}</strong>`"></p>
        </div>
      </div>
      <div class="modelReview__flag">
        <div class="modelReview__status">
          Revisión
          <span class="modelReview__msg">de Habitación</span>
          <i v-if="model.reviewRoom === 'success'" class="fas fa-check-circle check"></i>
          <i v-else-if="model.reviewRoom === 'pending'" class="fas fa-pencil-alt pencil"></i>
          <i v-else class="fas fa-times-circle times"></i>
        </div>
        <div class="modelReview__status">
          Estado
          <span class="modelReview__msg modelReview__msg--left">de Habitación</span>
          <i v-if="model.statusRoom === 'success'" class="fas fa-check-circle check"></i>
          <i v-else-if="model.statusRoom === 'pending'" class="fas fa-pencil-alt pencil"></i>
          <i v-else class="fas fa-times-circle times"></i>
        </div>
        <div class="modelReview__status">
          Mantenimiento
          <i v-if="model.statusMantenienceRoom === 'required'" class="fas fa-tools tool"></i>
          <i v-else-if="model.statusMantenienceRoom === 'pending'" class="fas fa-pencil-alt pencil"></i>
          <i v-else class="fas fa-check-circle check"></i>
        </div>
      </div>
    </li>
    <div class="modelReview__form" v-if="showForm">
      <reviewRoom :data="reviewData" />
    </div>
  </div>
</template>

<script>
import reviewRoom from "@/components/widgets/Reviews";
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    monitor: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showForm: false,
      reviewData: null,
    };
  },
  components: {
    reviewRoom,
  },
  mounted: async function() {
    this.$root.$on("closeReview", () => {
      this.showForm = false;
    });
  },

  methods: {
    showFormReview() {
      if (this.model.reviewRoom === "pending") {
        this.showForm = !this.showForm;
      }
    },
    objectPropForm() {
      this.reviewData = {
        id: this.model._id,
        userNameMonitor: this.monitor.user,
        turnOfTransmition: this.model.workShift.name ? this.model.workShift : this.model.lastLogin,
        userNameModel: this.model.user,
        city: this.model.city,
        sede: this.model.office,
        room: this.model.room,
        typeForm: "isCreate",
      };
    },
  },
};
</script>

<style lang="scss">
.modelReview {
  max-width: $tablet_width;
  padding: $mpadding;
  margin: $mpadding/2 0;
  border-radius: $mradius;
  box-shadow: $dshadow;
  background: $white;
  .check {
    margin-left: 10px;
    font-size: 20px;
    color: $japanese_laurel;
  }
  &__img {
    width: 3.5rem;
    height: 3.5rem;
    flex-shrink: 0;
  }
  .times {
    margin-left: 10px;
    font-size: 20px;
    color: $lipstick;
  }
  .pencil {
    margin-left: 10px;
    font-size: 16px;
    color: $chicago;
  }
  .tool {
    color: $lipstick;
  }
  &__li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  &__info {
    text-align: center;
  }
  &__status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: $mradius;
    padding: 0 $mpadding;
  }
  &__circle {
    display: flex;
    margin-left: 15px;
    width: 15px;
    height: 15px;
    border: 2px solid $chicago;
    margin-right: 5px;
    border-radius: 100%;
    background-color: $gray-edward;
    &--isReview {
      background: $japanese_laurel;
    }
    &--isNotReview {
      background: $lipstick;
    }
  }
  &__msg {
    display: none;
  }
}
@media (min-width: $max-width) {
  .modelReview {
    padding: $mpadding $mpadding * 2;
    &__info {
      display: flex;
    }
    &__items {
      text-align: left;
      margin-left: $mpadding;
    }
    &__msg {
      display: flex;
      margin-left: 5px;
      &--left {
        margin-left: 0;
      }
    }
  }
}
</style>
